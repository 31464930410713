@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Rabar_037";
    src: url('./Font/Rabar_037.ttf');
}

@font-face {
    font-family: "Rabar_038";
    src: url('./Font/Rabar_038.ttf');
}

@font-face {
    font-family: "Rabar_041";
    src: url('./Font/Rabar_041.ttf');
}

@font-face {
    font-family: "Icon";
    src: url('./Font/SocialMediaTwo-3zw1L.ttf');
}


.blur-text {
    text-shadow: 0 0 19.5px rgba(212, 30, 67, 0.61);
}

.blur-text-Us {
    text-shadow: 0 0 12.8px #D41E43;
}

.blur-text-home {
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.43);
}

.rfm-child {
    @apply text-white max-sm:-mx-5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.swiper-pagination .swiper-pagination-bullet {
    opacity: 1;
    background-color: #D9D9D9;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background-color: #D41E44;
}

.swiper {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}


@media (max-width: 375px) {
    #text-slide-home {
        font-size: 11px;
    }
}

@media (max-width:700px) {
    .swiper-pagination .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
    }
}

.text-shadow-example {
    text-shadow:
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 0px 6px rgb(0, 0, 0);
}

.Background-Us-Manager {
    background: linear-gradient(
      -83deg, 
      rgba(255, 255, 255, 0.15) 0%, 
      rgba(36, 36, 36, 0.15) 26%, 
      rgba(249, 249, 249, 0.15) 47%, 
      rgba(36, 36, 36, 0.15) 75%, 
      rgba(255, 255, 255, 0.15) 100%
    );
}
.Background-Us-Designer {
    background: linear-gradient(
      -95deg, 
      rgba(255, 255, 255, 0.15) 0%, 
      rgba(36, 36, 36, 0.15) 26%, 
      rgba(249, 249, 249, 0.15) 47%, 
      rgba(36, 36, 36, 0.15) 75%, 
      rgba(255, 255, 255, 0.15) 100%
    );
}
.Background-Us-Telegram {
    background: linear-gradient(
      -89deg, 
      rgba(255, 255, 255, 0.15) 0%, 
      rgba(36, 36, 36, 0.15) 26%, 
      rgba(249, 249, 249, 0.15) 47%, 
      rgba(36, 36, 36, 0.15) 75%, 
      rgba(255, 255, 255, 0.15) 100%
    );
}
.Background-Us-Facebook {
    background: linear-gradient(
      -109deg, 
      rgba(255, 255, 255, 0.15) 0%, 
      rgba(36, 36, 36, 0.15) 26%, 
      rgba(249, 249, 249, 0.15) 47%, 
      rgba(36, 36, 36, 0.15) 75%, 
      rgba(255, 255, 255, 0.15) 100%
    );
}

/* Define the font classes */

#NavbarLink  {
    @apply font-Rabar_038;
}


/* Hover effect */
#NavbarLink li:hover {
    font-family: "Rabar_037", sans-serif;
}

/* When hovering over one list item, change all others to font-Rabar_038 */


/* Active state for the NavLink */
#NavbarLink .active {
    font-family: "Rabar_037", sans-serif;
}
